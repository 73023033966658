<!--
--------------------------------------------------------------------------------
<copyright file="SalesPerSalespersonTimeline.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.salespeople_abc_groups') }}</summary>
      <p class="text">
        {{ $t('widget_info_text.salespeople_abc_groups_txt') }}
      </p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.salespeople_group_a') }}</span>
          - {{ $t('widget_info_text.salespeople_group_a_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.salespeople_group_b') }}</span>
          - {{ $t('widget_info_text.salespeople_group_b_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.salespeople_group_c') }}</span>
          - {{ $t('widget_info_text.salespeople_group_c_txt') }}
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">
        {{ $t('widget_info_text.scale_selected_period') }}
      </p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">
        {{ $t('widget_info_text.sales_per_salesperson_timeline_x_axis') }}
      </p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p class="text">
        {{ $t('widget_info_text.sales_per_salesperson_timeline_bars') }}
      </p>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class SalesPerSalespersonTimeline extends Vue {}
</script>
